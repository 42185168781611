import React, { Component } from "react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { format } from 'date-fns'

import "./Invoices.css";

class InvoiceFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterDate: "",
            filterCustomer: "",
            filterCountry: "",
            filterAccountManager: "",
        }

        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    handleFilterChange(evt) {
        if (evt.target.name === 'reset') {
            this.setState({
                filterDate: "",
                filterCustomer: "",
                filterCountry: "",
                filterAccountManager: ""
            })

			this.props.handleChange([]);
        }
        else {
            this.setState({
                [evt.target.name]: evt.target.value.toLowerCase()
            }, function () {
				this.props.handleChange([
					{ key: 'date', value: this.state.filterDate },
					{ key: 'customer', value: this.state.filterCustomer },
					{ key: 'country', value: this.state.filterCountry },
					{ key: 'accountManager', value: this.state.filterAccountManager }
				]);
            });
        }
    }

	handleDateChange(selectedDate) {
		this.setState({ filterDate: selectedDate }, function () {
			this.props.handleChange([
				{ key: 'date', value: this.state.filterDate },
				{ key: 'customer', value: this.state.filterCustomer },
				{ key: 'country', value: this.state.filterCountry },
				{ key: 'accountManager', value: this.state.filterAccountManager }
			]);
		});
    }

    render() {
        return (
            <form>
                <fieldset className="form-group">
                    <DayPickerInput
                        placeholder='Date (yyyy-mm-dd)'
                        value={this.state.filterDate}
                        selectedDate={this.state.filterDate}
                        id="filterDate"
                        onDayChange={date => this.handleDateChange((date && format(date, 'YYYY-MM-DD')) || '')}
                        inputProps={{
                            className: "form-control",
                            style: { width: "220px", display: "inline" }
                        }}
                    />
                    <input
                        name="filterCustomer"
                        id="filterCustomer"
                        type="text"
                        placeholder="Customer"
                        className="form-control"
                        style={{
                            width: "220px",
                            display: "inline"
                        }}
                        onChange={this.handleFilterChange}
                    />
                    <input
                        name="filterCountry"
                        id="filterCountry"
                        type="text"
                        placeholder="Country"
                        className="form-control"
                        style={{
                            width: "220px",
                            display: "inline"
                        }}
                        onChange={this.handleFilterChange}
                    />
                    <input
                        name="filterAccountManager"
                        id="filterAccountManager"
                        type="text"
                        placeholder="Account Manager"
                        className="form-control"
                        style={{
                            width: "220px",
                            display: "inline"
                        }}
                        onChange={this.handleFilterChange}
                    />
                    <input
                        name="reset"
                        className="btn btn-default"
                        type="reset"
                        value="Clear"
                        style={{
                            marginLeft: "10px"
                        }}
                        onClick={this.handleFilterChange}
                    />
                </fieldset>
            </form>
        );
    }
}

export default InvoiceFilter;
