import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";

import { adalApiFetch } from "../../adalConfig";
import "./Invoices.css";
import InvoicesTable from "./InvoicesTable";
import InvoiceFilter from "./InvoiceFilter";
import FormatFilterQuery from "./filterUtils";

import { filter, includes } from "lodash";

export class AcceptableInvoices extends Component {
    displayName = AcceptableInvoices.name;

    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            activePage: 1,
            invoices: [],
            loading: true,
            filters: '',
        };

        this.acceptInvoices = this.acceptInvoices.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        adalApiFetch(fetch, "api/invoices/count/new", {})
            .then(response => response.text().then(text => {
                this.setState({ count: Number(text) });
                adalApiFetch(fetch, "api/Invoices/new?page=" + this.state.activePage, {})
                    .then(response => response.json())
                    .then(data => {
                        this.setState({ invoices: data, loading: false });
                    });
			}));
    }

    acceptInvoices(invoiceIds) {
        adalApiFetch(fetch, "api/Invoices/accept", {
            method: "POST",
            body: JSON.stringify(invoiceIds),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(function(response) {
                if (response.ok) {
                    return null;
                } else {
                    throw new Error(
                        "Error: " + response.status + " while accepting invoice"
                    );
                }
            })
            .then(data => {
                toast.success("Invoice accepted successfully", {
                    autoClose: 3500
                });

                this.setState({
                    invoices: filter(this.state.invoices, function(i) {
                        return !includes(invoiceIds, i.invoiceId);
                    })
                });
            })
            .catch(function(error) {
                console.log(error);
                toast.error("Failed to accept invoice", {
                    autoClose: 3500
                });
            });
    }

    handlePageChange(activePage) {
        adalApiFetch(fetch, "api/Invoices/new?page=" + activePage + this.state.filters, {})
            .then(response => response.json())
            .then(data => {
                this.setState({ activePage: activePage, invoices: data });
			})
			.catch(error => {
				toast.error("Failed to load invoices", {
					autoClose: 3500
				});
			});
    }

	handleChange(filterArray) {
		let filterQuery = FormatFilterQuery(filterArray);

		if (filterQuery !== this.state.filters) {
			adalApiFetch(fetch, "api/invoices/count/new" + (filterQuery !== '' ? '?' + filterQuery.slice(1) : ''), {})
				.then(response => response.text().then(text => {
					this.setState({ count: Number(text) });
					adalApiFetch(fetch, "api/Invoices/new?page=1" + filterQuery, {})
						.then(response => response.json())
						.then(data => {
							this.setState({ activePage: 1, invoices: data, filters: filterQuery });
						})
						.catch(error => {
							toast.error("Failed to load invoices", {
								autoClose: 3500
							});
						});
				}))
				.catch(error => {
					toast.error("Failed to load invoice count", {
						autoClose: 3500
					});
				});
		}
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="invoice-list">
                    <h1>Invoices (waiting for acceptance)</h1>
                    <p>
                        <em>Loading...</em>
                    </p>
                </div>
            );
        }

        return (
            <div className="invoice-list">
                <h1>Invoices (waiting for acceptance)</h1>

                <InvoiceFilter handleChange={this.handleChange} />

                <InvoicesTable
                    totalCount={this.state.count}
                    activePage={this.state.activePage}
                    invoices={this.state.invoices}
                    showAction
                    actionName="Accept"
                    onSelectedIdsAction={this.acceptInvoices}
                    showSecondaryAction={false}
                    handlePageChange={this.handlePageChange}
                />
                <ToastContainer />
            </div>
        );
    }
}
