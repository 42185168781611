import React, { Component } from "react";
import { adalApiFetch } from "../../adalConfig";
import { dateFormat } from "../../utils/constants";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import { filter } from "lodash";

class Sites extends Component {
    displayName = Sites.name;

    constructor(props) {
        super(props);
        this.state = {
            sites: [],
            filteredSites: [],
            loading: true,
            withoutCustomers: false,
            filterName: "",
            filterCompany: "",
            filterLicense: "",
            filterCustomer: "",
            filterOwner: ""
        };

        adalApiFetch(fetch, "api/Sites", {})
            .then(response => response.json())
            .then(data => {
                this.setState({
                    sites: data, // SiteListDTO
                    filteredSites: data,
                    loading: false
                });
            });

        this.onListFilterCheck = this.onListFilterCheck.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderSitesTable = this.renderSitesTable.bind(this);
    }

    renderSitesTable(sites) {
        return (
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Site ID</th>
                        <th>Name</th>
                        <th>Company</th>
                        <th>License</th>
                        <th>Device count</th>
                        <th>Subscription start</th>
                        <th>Customer</th>
                        <th colSpan="2">HubSpotOwner</th>
                    </tr>
                </thead>
                <tbody>
                    {sites.map(site => (
                        <tr
                            key={site.siteId}
                            onClick={() =>
                                this.props.history.push("/site/" + site.siteId)
                            }
                            style={{ cursor: "pointer" }}
                        >
                            <td>{site.siteId}</td>
                            <td>{site.name}</td>
                            <td>{site.company}</td>
                            <td>{site.licenseName}</td>
                            <td>{site.deviceCount}</td>
                            <td>
                                {moment(site.subscriptionStartDate).format(
                                    dateFormat
                                )}
                            </td>
                            <td>
                                {site.customerName}
                            </td>
                            <td>{site.hubSpotOwnerEmail}</td>
                            <td>
                                <Link to={"/site/" + site.siteId}>
                                    <i className="fa fa-arrow-circle-right" />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="sites-list">
                    <h1>Sites</h1>
                    <p>
                        <em>Loading...</em>
                    </p>
                </div>
            );
        }

        let filteredSites;
        if (this.state.withoutCustomers) {
            filteredSites = this.state.filteredSites.filter(
                site => site.customerName === null
            );
        } else {
            filteredSites = this.state.filteredSites;
        }

        return (
            <div className="sites-list">
                <h1>Sites</h1>
                <input
                    id="withoutCustomersOnly"
                    onChange={this.onListFilterCheck}
                    type="checkbox"
                    checked={this.state.withoutCustomers}
                />
                <label
                    className="without-customers-label"
                    htmlFor="withoutCustomersOnly"
                >
                    Only sites without customers
                </label>

                <form>
                    <fieldset className="form-group">
                        <input
                            name="filterName"
                            id="filterName"
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            style={{
                                width: "220px",
                                display: "inline"
                            }}
                            onChange={this.handleChange}
                        />
                        <input
                            name="filterCompany"
                            id="filterCompany"
                            type="text"
                            placeholder="Company"
                            className="form-control"
                            style={{
                                width: "220px",
                                display: "inline",
                                marginLeft: "10px"
                            }}
                            onChange={this.handleChange}
                        />
                        <input
                            name="filterLicense"
                            id="filterLicense"
                            type="text"
                            placeholder="License"
                            className="form-control"
                            style={{
                                width: "220px",
                                display: "inline",
                                marginLeft: "10px"
                            }}
                            onChange={this.handleChange}
                        />
                        <input
                            name="filterCustomer"
                            id="filterCustomer"
                            type="text"
                            placeholder="Customer"
                            className="form-control"
                            style={{
                                width: "220px",
                                display: "inline",
                                marginLeft: "10px"
                            }}
                            onChange={this.handleChange}
                        />
                        <input
                            name="filterOwner"
                            id="filterOwner"
                            type="text"
                            placeholder="Hubspot owner"
                            className="form-control"
                            style={{
                                width: "220px",
                                display: "inline",
                                marginLeft: "10px"
                            }}
                            onChange={this.handleChange}
                        />
                        <input
                            name="reset"
                            className="btn btn-default"
                            type="reset"
                            value="Clear"
                            style={{
                                marginLeft: "10px"
                            }}
                            onClick={this.handleChange}
                        />
                    </fieldset>
                </form>

                {this.renderSitesTable(filteredSites)}
            </div>
        );
    }

    handleChange(evt) {
        if (evt.target.name === "reset") {
            this.setState({
                filterName: "",
                filterCompany: "",
                filterLicense: "",
                filterCustomer: "",
                filterOwner: ""
            });
        } else {
            this.setState({
                [evt.target.name]: evt.target.value.toLowerCase()
            });
        }

        this.setState(prevState => {
            const filteredSites = filter(this.state.sites, function (site) {
                const name = site.name.toLowerCase();
                const company = site.company.toLowerCase();
                const license = site.licenseName.toLowerCase();
                const customer = site.customerName ? site.customerName.toLowerCase() : "";
                const owner = site.hubSpotOwnerEmail? site.hubSpotOwnerEmail.toLowerCase() : "";

                return (
                    name.includes(prevState.filterName) &&
                    company.includes(prevState.filterCompany) &&
                    license.includes(prevState.filterLicense) &&
                    customer.includes(prevState.filterCustomer) &&
                    owner.includes(prevState.filterOwner)
                );
            });

            return { filteredSites };
        });
    }

    onListFilterCheck() {
        this.setState({ withoutCustomers: !this.state.withoutCustomers });
    }
}

export default withRouter(Sites);
