import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import Customers from "./components/customer/Customers";
import { Customer } from "./components/customer/Customer";
import { Users } from "./components/Users";
import Sites from "./components/site/Sites";
import { Site } from "./components/site/Site";
import { Invoices } from "./components/invoices/Invoices";
import { InvoicesHistory } from "./components/invoices/InvoicesHistory";
import { AcceptableInvoices } from "./components/invoices/AcceptableInvoices";
import { Invoice } from "./components/invoices/Invoice";

export default class App extends Component {
    displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path="/" render={(props) => <Customers {...props} status={"active"} />} />
                <Route exact path="/customers" render={(props) => <Customers {...props} status={"active"} />} />
                <Route exact path="/customers-removed" render={(props) => <Customers {...props} status={"removed"} />} />
                <Route exact path="/customer" component={Customer} />
                <Route path="/customer/:customerId" component={Customer} />
                <Route
                    path="/acceptable-invoices"
                    component={AcceptableInvoices}
                />
                <Route path="/invoices" component={Invoices} />
                <Route path="/invoices-history" render={(props) => <InvoicesHistory {...props} status={"sent"} />} />
                <Route path="/invoices-removed" render={(props) => <InvoicesHistory {...props} status={"removed"} />} />
                <Route exact path="/invoice/:invoiceId" component={Invoice} />
                <Route path="/users" component={Users} />
                <Route exact path="/sites" component={Sites} />
                <Route exact path="/site/:siteId" component={Site} />
            </Layout>
        );
    }
}
