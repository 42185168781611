import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Invoices.css";

import { includes, pull } from "lodash";
import Pagination from "react-js-pagination";
require("bootstrap/less/bootstrap.less");

class InvoicesTable extends Component {
    displayName = InvoicesTable.name;

    constructor(props) {
        super(props);
        this.state = { selected: [], allSelected: false };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
    }

    handleInputChange(invoiceId) {
        let selected = [...this.state.selected];

        if (includes(selected, invoiceId)) {
            pull(selected, invoiceId);
        } else {
            selected.push(invoiceId);
        }

        this.setState({ selected });
    }

    toggleSelectAll() {
        let selected = [];

        if (!this.state.allSelected) {
            for (let invoice of this.props.invoices) {
                selected.push(invoice.invoiceId);
            }
        }

        this.setState({ selected, allSelected: !this.state.allSelected });
    }

    invoiceStatusString(status) {
        switch (status) {
            case 1:
                return "New";
            case 2:
                return "Accepted";
            case 3:
                return "Sent";
            case 4:
                return "Removed";
            default:
                return "not defined";
        }
    }

    render() {
        const actionButton = this.props.showAction ? (
            <button
                disabled={this.state.selected.length === 0}
                type="button"
                className="btn btn-success"
                onClick={() =>
                    this.props.onSelectedIdsAction(this.state.selected)
                }
            >
                {this.props.actionName}
            </button>
        ) : null;

        const secondaryActionButton = this.props.showSecondaryAction ? (
            <button
                disabled={this.state.selected.length === 0}
                type="button"
                className="btn btn-warning"
                onClick={() =>
                    this.props.onSelectedIdsSecondaryAction(this.state.selected)
                }
            >
                {this.props.secondaryActionName}
            </button>
        ) : null;

        return (
            <div>
                <Pagination
                    activePage={this.props.activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={this.props.totalCount}
                    pageRangeDisplayed={10}
                    onChange={this.props.handlePageChange}
                />
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    name="selectAllInvoices"
                                    type="checkbox"
                                    checked={this.state.allSelected}
                                    onChange={this.toggleSelectAll}
                                />
                            </th>
                            <th>Number</th>
                            <th>Date</th>
                            <th>Months</th>
                            <th>Customer</th>
                            <th>Country</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Account Manager</th>
                            <th colSpan="2" />
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.invoices.map(invoice => (
                            <tr key={invoice.invoiceId}>
                                <td>
                                    <input
                                        name="selectInvoice"
                                        type="checkbox"
                                        checked={includes(
                                            this.state.selected,
                                            invoice.invoiceId
                                        )}
                                        onChange={() =>
                                            this.handleInputChange(
                                                invoice.invoiceId
                                            )
                                        }
                                    />
                                </td>

                                <td>{invoice.invoiceNumber && invoice.invoiceNumber}</td>
                                <td>
                                    {new Intl.DateTimeFormat(
                                        window.navigator.language,
                                        {}
                                    ).format(new Date(invoice.invoiceDate))}
                                </td>
                                <td>{invoice.invoiceInterval}</td>
                                <td>{invoice.customer.name}</td>
                                <td>{invoice.customer.country}</td>
                                <td>
                                    {this.invoiceStatusString(invoice.status)}
                                </td>
                                <td>
                                    {new Intl.NumberFormat(
                                        window.navigator.language,
                                        {
                                            style: "currency",
                                            currency: invoice.currency
                                        }
                                    ).format(invoice.amount)}
                                </td>
                                <td>{invoice.customer.accountManager}</td>
                                <td>
                                    <Link to={"/invoice/" + invoice.invoiceId}>
                                        <i className="fa fa-arrow-circle-right" />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {actionButton}
                {secondaryActionButton}
            </div>
        );
    }
}

export default InvoicesTable;
