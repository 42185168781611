import "./Customer.css";
import { adalApiFetch } from "../../adalConfig";
import { invoiceIntervals, paymentTerms } from "../../utils/arrays";
import ModalDialog from "../../utils/ModalDialog";

import { includes, map, remove } from "lodash";
import React, { Component } from "react";
import Link from "react-router-dom/Link";
import DayPickerInput from "react-day-picker/DayPickerInput";

import { format, parse } from "date-fns";
import { dateFormat } from "../../utils/constants";

import "react-day-picker/lib/style.css";

const SiteGroup = props => {
    function formatDate(date, dateFormat) {
        return format(date, dateFormat);
    }

    function parseDate(str, dateFormat) {
        return parse(str, dateFormat);
    }

    return (
        <div className="site-group">
            <div className="row">
                <div className="col-md-11">
                    <h4 className="site-group-title">
                        {props.group.siteGroupId < 0
                            ? "New site group"
                            : "Site group " + props.group.siteGroupId}
                    </h4>
                </div>

                <div className="col-md-1">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() =>
                            props.onRemoveGroup(props.group.siteGroupId)
                        }>
                        <i className="fa fa-times fa-lg" aria-hidden="true" />
                    </button>
                </div>
            </div>

            <div className="site-invoice-interval">
                <div className="row group-edit-row">
                    <div className="col-md-3">Invoice interval (months)</div>
                    <div className="col-md-1">
                        <select
                            name="invoiceinterval"
                            onChange={event =>
                                props.onInvoiceIntervalChanged(
                                    props.group.siteGroupId,
                                    event.target.value
                                )
                            }
                            value={props.group.invoiceInterval}>
                            {invoiceIntervals.map(value => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row group-edit-row">
                    <div className="col-md-3">Next Invoice</div>
                    <div className="col-md-6">
                        <DayPickerInput
                            value={props.group.nextInvoiceDate && new Date(props.group.nextInvoiceDate)}
                            dayPickerProps={{ initialMonth: props.group.nextInvoiceDate ? new Date(props.group.nextInvoiceDate) : new Date() }}
                            format={dateFormat}
                            placeholder={
                                props.group.nextInvoiceDate ?
                                format(
                                    parse(props.group.nextInvoiceDate),
                                    dateFormat
                                ) : 'Select date'
                            }
                            parseDate={parseDate}
                            formatDate={formatDate}
                            onDayChange={selectedDay =>
                                props.onGroupInvoiceDateChanged(
                                    props.group.siteGroupId,
                                    (selectedDay &&
                                        format(selectedDay, dateFormat)) ||
                                        undefined
                                )
                            }
                        />
                    </div>
                </div>

                <div className="row group-edit-row">
                    <div className="col-md-3">Next Invoice period start date</div>
                    <div className="col-md-6">
                        <DayPickerInput
                            value={props.group.nextInvoicePeriodStartDate && new Date(props.group.nextInvoicePeriodStartDate)}
                            dayPickerProps={{ initialMonth: props.group.nextInvoiceDate ? new Date(props.group.nextInvoiceDate) : new Date() }}
                            format={dateFormat}
                            placeholder={
                                props.group.nextInvoicePeriodStartDate ?
                                format(
                                    parse(props.group.nextInvoicePeriodStartDate),
                                    dateFormat
                                ) : 'Select date'
                            }
                            parseDate={parseDate}
                            formatDate={formatDate}
                            onDayChange={selectedDay =>
                                props.onGroupInvoicePeriodStartDateChanged(
                                    props.group.siteGroupId,
                                    (selectedDay &&
                                        format(selectedDay, dateFormat)) ||
                                    undefined
                                )
                            }
                        />
                    </div>
                </div>


                <div className="row group-edit-row">
                    <div className="col-md-3">Payment term</div>
                    <div className="col-md-1">
                        <select
                            name="paymentTerm"
                            onChange={event =>
                                props.onGroupPaymentTermChanged(
                                    props.group.siteGroupId,
                                    event.target.value
                                )
                            }
                            value={props.group.paymentTermNetDays}>
                            {paymentTerms.map(value => (
                                <option key={value} value={value}>
                                    {value} days net
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

            </div>


            <h5 className="site-group-title">Sites:</h5>

            <div className="site-group-table">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Site ID</th>
                            <th>Name</th>
                            <th>Company</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.group.site.map(site => (
                            <tr key={site.siteId} style={{color: site.status === 2 ? 'red' : 'inherit'}}>
                                <td>{site.siteId}</td>
                                <td>
                                    <Link to={"/site/" + site.siteId}>
                                        {site.name}
                                    </Link>
                                </td>
                                <td>{site.company}{site.status === 2 && " (removed)"}</td>
                                <td>
                                    <button
                                        id="remove-customer-button"
                                        type="button"
                                        className="btn btn-link"
                                        onClick={() =>
                                            props.removeSite(
                                                props.group.siteGroupId,
                                                site
                                            )
                                        }>
                                        <i className="fa fa-minus-circle" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <button
                type="button"
                className="btn btn-primary add-site-button"
                onClick={() => {
                    props.openSites(props.group.siteGroupId);
                }}>
                add site
            </button>
        </div>
    );
};

export class SiteGroupList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            sites: [],
            loadingSites: true,
            selectedSites: [],
            selectedGroupId: -1,
        };

        this.closeModal = this.closeModal.bind(this);

        this.onSiteSelected = this.onSiteSelected.bind(this);
        this.addSelected = this.addSelected.bind(this);

        this.openSiteDialog = this.openSiteDialog.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
    }

    componentDidMount() {
        adalApiFetch(fetch, "api/Sites?nosg=true", {})
            .then(response => response.json())
            .then(data => {
                function compare(a, b) {
                    if (a.company < b.company) return -1;
                    if (a.company > b.company) return 1;
                    return 0;
                }

                data.sort(compare);

                this.setState({ sites: data, loadingSites: false });
            });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    addSelected() {
        if (this.state.selectedSites.length > 0) {
            this.closeModal();
            this.props.siteSelected(
                this.state.selectedGroupId,
                this.state.selectedSites
            );
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <p>
                    <em>Loading...</em>
                </p>
            );
        }

        if (!this.props.groups || this.props.groups.length === 0) {
            return <div>Customer has no groups yet</div>;
        }

        const selectedIds = map(this.state.selectedSites, "siteId");

        const modalContent = this.state.loadingSites ? (
            <div className="loading-sites-message">
                Loading sites... please wait...
            </div>
        ) : (
            <table className="table">
                <thead>
                    <tr>
                        <th>Site ID</th>
                        <th>Site name</th>
                        <th>Company</th>
                    </tr>
                </thead>
                <tbody>
                        {this.state.sites.map(site => (
                        <tr
                            className={
                                includes(selectedIds, site.siteId)
                                    ? "selected"
                                    : ""
                            }
                            key={site.siteId}
                            onClick={() => this.onSiteSelected(site)}>
                            <td>{site.siteId}</td>
                            <td>{site.name}</td>
                            <td>{site.company}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );

        return (
            <div id="siteGroupList" className="site-group-list">
                {this.props.groups.map(group => (
                    <SiteGroup
                        key={group.siteGroupId}
                        group={group}
                        openSites={this.openSiteDialog}
                        removeSite={this.onRemoveClick}
                        onInvoiceIntervalChanged={
                            this.props.onInvoiceIntervalChanged
                        }
                        onGroupInvoiceDateChanged={
                            this.props.onGroupInvoiceDateChanged
                        }
                        onGroupInvoicePeriodStartDateChanged={
                            this.props.onGroupInvoicePeriodStartDateChanged
                        }
                        onGroupPaymentTermChanged={
                            this.props.onGroupPaymentTermChanged
                        }
                        onRemoveGroup={this.props.removeSiteGroup}
                    />
                ))}

                {this.state.modalIsOpen && (
                    <ModalDialog
                        closeModal={this.closeModal}
                        title="Select site(s)"
                        addSelected={this.addSelected}>
                        {modalContent}
                    </ModalDialog>
                )}
            </div>
        );
    }

    onSiteSelected(site) {
        let selectedSites = [...this.state.selectedSites];

        if (includes(selectedSites, site)) {
            remove(selectedSites, site);
        } else {
            selectedSites.push(site);
        }

        this.setState({ selectedSites: selectedSites });
    }

    openSiteDialog(siteGroupId) {
        this.setState({
            modalIsOpen: true,
            selectedSites: [],
            selectedGroupId: siteGroupId,
        });
    }

    onRemoveClick(siteGroupId, site) {
        this.props.siteRemoved(siteGroupId, site);
    }
}
