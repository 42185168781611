import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Glyphicon, Nav, Navbar, NavItem, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./NavMenu.css";

export class NavMenu extends Component {
    displayName = NavMenu.name;

    render() {
        return (
            <Navbar inverse fixedTop collapseOnSelect fluid>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to={"/"}>Invoice</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav>
                        <NavDropdown
                            id="customersDropDown"
                            eventKey={3}
                            title="Customers"
                        >
                        <LinkContainer to={"/customers"}>
                            <NavItem eventKey={2}>
                                <Glyphicon glyph="th-list" /> Active
                            </NavItem>
                            </LinkContainer>
                            <LinkContainer to={"/customers-removed"}>
                                <NavItem eventKey={2}>
                                    <Glyphicon glyph="th-list" /> Removed
                            </NavItem>
                            </LinkContainer>
                        </NavDropdown>
                        <NavDropdown
                            id="invoicesDropDown"
                            eventKey={3}
                            title="Invoices"
                        >
                            <LinkContainer to={"/acceptable-invoices"}>
                                <NavItem eventKey={3.1}>
                                    <Glyphicon glyph="th-list" /> Waiting for
                                    Acceptance
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to={"/invoices"}>
                                <NavItem eventKey={3.2}>
                                    <Glyphicon glyph="th-list" /> Accepted
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to={"/invoices-history"}>
                                <NavItem eventKey={3.3}>
                                    <Glyphicon glyph="th-list" /> History
                                </NavItem>
                            </LinkContainer>
                            <LinkContainer to={"/invoices-removed"}>
                                <NavItem eventKey={3.4}>
                                    <Glyphicon glyph="th-list" /> Removed
                                </NavItem>
                            </LinkContainer>
                        </NavDropdown>

                        <LinkContainer to={"/sites"}>
                            <NavItem eventKey={4}>
                                <Glyphicon glyph="th-list" /> Sites
                            </NavItem>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
