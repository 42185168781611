import { adalApiFetch } from "../../adalConfig";
import { mapoUrl, hubSpotDealUrl } from "../../externalConfig";
import "./Site.css";
import ModalDialog from "../../utils/ModalDialog";
import { dateFormat } from "../../utils/constants";
import moment from "moment";

import React, { Component } from "react";
import Link from "react-router-dom/Link";
import { findIndex, map } from "lodash";
import { ToastContainer, toast } from "react-toastify";

export class Site extends Component {
    displayName = Site.name;

    constructor(props) {
        super(props);
        this.state = {
            openProductModal: false,
            selectedProduct: null,
            siteId: 0,
            site: null,
            loading: true,
            products: [],
        };

        this.closeModal = this.closeModal.bind(this);
        this.onProductSelected = this.onProductSelected.bind(this);
        this.addSelected = this.addSelected.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkDeviceCount = this.checkDeviceCount.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.siteId) {
            this.setState({ siteId: this.props.match.params.siteId });

            adalApiFetch(
                fetch,
                "api/Sites/" + this.props.match.params.siteId,
                {}
            )
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        site: data, // Site
                        loading: false,
                    });
                });
        }

        adalApiFetch(fetch, "/api/Products", {})
            .then(response => response.json())
            .then(data => {
                this.setState({ products: data });
            });
    }

    closeModal() {
        this.setState({
            openProductModal: false,
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            site: {
                ...this.state.site,
                [name]: value,
            },
        });
    }

    licenseRow() {
        let licenceContent = <div />;
        if (this.state.site.licenseId) {
            const site = this.state.site;

            const currencyCode = this.getCurrencyCode(site) || "";

            const defaultPrice =
                currencyCode === "SEK"
                    ? site.licenseDefaultPrice * 10
                    : site.licenseDefaultPrice;

            licenceContent = (
                <div>
                    <div className="row site-info-row">
                        <div className="col-md-3">Name:</div>
                        <div className="col-md-3">{site.licenseName}</div>
                    </div>
                    <div className="row site-info-row">
                        <div className="col-md-3">Fixed device count:</div>
                        <div className="col-md-3">
                            <button
                                type="button"
                                className="btn btn-default product-row-button"
                                onClick={() => this.setCountForLicense()}>
                                {site.agreedLicenseCount
                                    ? site.agreedLicenseCount
                                    : "Set"}
                            </button>
                        </div>
                    </div>
                    <div className="row site-info-row">
                        <div className="col-md-3">
                            Fixed count is minimum count:
                        </div>
                        <div className="col-md-3">
                            <input
                                disabled={
                                    !site.agreedLicenseCount ||
                                    site.agreedLicenseCount === 0
                                }
                                alt="Whether the fixed device count is only minimum"
                                name="agreedLicenseCountIsMinimum"
                                type="checkbox"
                                checked={site.agreedLicenseCountIsMinimum}
                                onChange={this.handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="row site-info-row">
                        <div className="col-md-3">Default price:</div>
                        <div className="col-md-3">
                            {defaultPrice + " " + currencyCode}
                        </div>
                    </div>
                    <div className="row site-info-row">
                        <div className="col-md-3">Fixed price per device:</div>
                        <div className="col-md-3">
                            <button
                                type="button"
                                className="btn btn-default product-row-button"
                                onClick={() => this.setPriceForLicense()}>
                                {this.state.site.agreedLicensePrice
                                    ? this.state.site.agreedLicensePrice
                                    : "Set"}
                            </button>
                            <span className="site-info-currency">
                                {this.state.site.agreedLicensePrice
                                    ? currencyCode
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="site-info">
                {licenceContent}
            </div>
        );
    }

    getCurrencyCode(site) {
        return site.siteGroup && site.siteGroup.currencyCode;
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="sites-list">
                    <h1>{"Site"}</h1>
                    <p>
                        <em>Loading...</em>
                    </p>
                </div>
            );
        }

        let modalContent;
        if (this.state.openProductModal) {
            const selectedId = this.state.selectedProduct
                ? this.state.selectedProduct.productId
                : -1;

            const selectedProductIds = map(
                this.state.site.siteProducts,
                "productId"
            );

            let filteredProducts = this.state.products.filter(
                product => !selectedProductIds.includes(product.productId)
            );

            modalContent = (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Product ID</th>
                            <th>Name</th>
                            <th>Default price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map(product => (
                            <tr
                                className={
                                    selectedId === product.productId
                                        ? "selected"
                                        : ""
                                }
                                key={product.productId}
                                onClick={() => this.onProductSelected(product)}>
                                <td>{product.productId}</td>
                                <td>{product.name}</td>
                                <td>{product.defaultPrice}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }

        const productTable = (
            <table className="table">
                <thead>
                    <tr>
                        <th>Product ID</th>
                        <th>Name</th>
                        <th>Default price</th>
                        <th>Device count</th>
                        <th>Fixed price</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.site.siteProducts.map(siteProducts => (
                        <tr key={siteProducts.productId}>
                            <td>{siteProducts.productId}</td>
                            <td>{siteProducts.name}</td>
                            <td>{siteProducts.defaultPrice}</td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-default product-row-button"
                                    onClick={() =>
                                        this.setCountForProduct(siteProducts)
                                    }>
                                    {siteProducts.count
                                        ? siteProducts.count
                                        : "Set"}
                                </button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-default product-row-button"
                                    onClick={() =>
                                        this.setPriceForProduct(siteProducts)
                                    }>
                                    {siteProducts.fixedPrice
                                        ? siteProducts.fixedPrice
                                        : "Set"}
                                </button>
                                <span className="site-info-currency">
                                    {siteProducts.fixedPrice
                                        ? this.getCurrencyCode(this.state.site) : ""}
                                </span>
                            </td>
                            <td>
                                <button
                                    id="remove-product-button"
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() =>
                                        this.removeProduct(siteProducts)
                                    }>
                                    <i className="fa fa-trash" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );

        return (
            <div className={this.state.site.status === 1 ? "sites-list" : "sites-list removed"}>
                {this.state.site.status === 1 ? <h1>{"Site " + this.state.siteId}</h1> : <h1>{"Removed site " + this.state.siteId}</h1>}

                {this.siteRow()}

                <h4>License:</h4>
                {this.licenseRow()}
                <h4>Products:</h4>
                {productTable}
                <div className="product-button-row">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.openProductDialog()}>
                        Add product
                    </button>
                    <button
                        type="button"
                        className="btn btn-success save-site-button"
                        onClick={() => this.saveSite()}>
                        Save
                    </button>
                </div>

                {this.state.openProductModal && (
                    <ModalDialog
                        closeModal={this.closeModal}
                        title="Select product"
                        addSelected={this.addSelected}>
                        {modalContent}
                    </ModalDialog>
                )}
                <ToastContainer />
            </div>
        );
    }

    siteRow() {
        const site = this.state.site;

        /*

        <SiteRow
                    site={this.state.site}
                    alarm={this.checkDeviceCount()}
                />

        */

        return (
            <div className="site-info">
                <div className="row site-info-row">
                    <div className="col-md-3">Name:</div>
                    <div className="col-md-3">{site.name}</div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">Company:</div>
                    <div className="col-md-3">{site.company}</div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">Subscription start:</div>
                    <div className="col-md-3">
                        {moment(site.subscriptionStartDate).format(dateFormat)}
                    </div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">Subscription end:</div>
                    <div className="col-md-3">
                        {site.subscriptionEndDate && moment(site.subscriptionEndDate).format(dateFormat)}
                    </div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">Customer:</div>
                    <div className="col-md-3">
                        {site.siteGroup && site.siteGroup.customerId ? (
                            <Link
                                to={
                                    "/customer/" +
                                    site.siteGroup.customerId
                                }>
                                {site.siteGroup.customerName}
                            </Link>
                        ) : null}
                    </div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">Currency:</div>
                    <div className="col-md-3">
                        {this.getCurrencyCode(site)}
                    </div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">Actual device count:</div>
                    <div className="col-md-3">
                        {site.deviceCount}
                        <span className="site-info-device-count-alarm">
                            {this.checkDeviceCount()}
                        </span>
                    </div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">HubSpot owner:</div>
                    <div className="col-md-3">
                        {site.hubSpotOwnerEmail}
                    </div>
                </div>
                <div className="row site-info-row">
                    <div className="col-md-3">External links:</div>
                    <div className="col-md-3">
                        <a href={`${mapoUrl}/site/${site.name}`} target='_blank'>Management Portal</a>
					</div>
					{site.hubSpotDealID !== null ? (
						<div className="col-md-3">
							<a href={`${hubSpotDealUrl}/${site.hubSpotDealID}`} target='_blank'>HubSpot deal</a>
						</div>
					) : null}
                </div>
            </div>
        );
    }

    checkDeviceCount() {
        const realCount = this.state.site.deviceCount;
        const licenceCount = this.state.site.agreedLicenseCount;
        let doAlarm = false;

        for (let product of this.state.site.siteProducts) {
            if (product.count && realCount > product.count) {
                doAlarm = true;
            }
        }

        if (
            !this.state.site.agreedLicenseCountIsMinimum &&
            licenceCount !== null &&
            licenceCount > 0 &&
            realCount > licenceCount
        ) {
            doAlarm = true;
        }

        if (doAlarm) {
            return "Real device count is higher than invoiced!";
        }

        return null;
    }

    onProductSelected(product) {
        this.setState({ selectedProduct: product });
    }

    addSelected() {
        if (this.state.selectedProduct) {
            let { site, selectedProduct } = this.state;

            if (this.state.openProductModal) {
                //Add product

                const siteProduct = {
                    siteId: site.siteId,
                    productId: selectedProduct.productId,
                    name: selectedProduct.name,
                    defaultPrice: selectedProduct.defaultPrice,
                    count: null,
                    fixedPrice: null,
                };
                
                this.setState({
                    site: {
                        ...site,
                        siteProducts: [...site.siteProducts, siteProduct],
                    },
                });
            }

            this.closeModal();
        }

        this.setState({ selectedProduct: null });
    }

    openProductDialog() {
        this.setState({
            openProductModal: true,
            selectedProduct: null,
        });
    }

    setCountForLicense() {
        let count = parseInt(
            prompt("Set device count for the license (0 to remove)", "0"),
            10
        );
        
        if (!isNaN(count)) {
            this.setState({
                site: {
                    ...this.state.site,
                    agreedLicenseCount: count === 0 ? null : count,
                },
            });
        }
    }

    setPriceForLicense() {
        let priceString = prompt(
            "Set fixed price for the license (0 to remove)",
            "0"
        );

        if (!priceString) {
            return;
        }

        priceString = priceString.replace(",", ".");
        let price = parseFloat(priceString, 10);

        if (!isNaN(price)) {
            this.setState({
                site: {
                    ...this.state.site,
                    agreedLicensePrice: price === 0 ? null : price,
                },
            });
        }
    }

    setCountForProduct(product) {
        let count = parseInt(
            prompt("Set device count for the product", "0"),
            10
        );

        if (!isNaN(count)) {
            let { site } = this.state;

            if (count === 0) {
                count = null;
            }

            product.count = count;
            let products = [...this.state.site.siteProducts];

            const index = findIndex(products, ["productId", product.productId]);
            products[index] = product;

            this.setState({
                site: {
                    ...site,
                    siteProducts: [...products],
                },
            });
        }
    }

    setPriceForProduct(product) {
        let priceString = prompt("Set fixed price for product", "0");

        if (!priceString) {
            return;
        }

        priceString = priceString.replace(",", ".");
        let price = parseFloat(priceString, 10);

        if (!isNaN(price)) {
            let { site } = this.state;

            product.fixedPrice = price;
            let products = [...this.state.site.siteProducts];
            const index = findIndex(products, ["productId", product.productId]);
            products[index] = product;

            this.setState({
                site: {
                    ...site,
                    siteProducts: [...products],
                },
            });
        }
    }

    removeProduct(product) {
        let { site } = this.state;
        let products = [...this.state.site.siteProducts];
        const index = findIndex(products, ["productId", product.productId]);
        if (index > -1) {
            products.splice(index, 1);
        }

        this.setState({
            site: {
                ...site,
                siteProducts: [...products],
            },
        });
    }

    saveSite() {
        let { site } = this.state;

        adalApiFetch(fetch, "/api/Sites/" + site.siteId, {
            method: "PUT",
            body: JSON.stringify(site),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                if (response.ok) {
                    toast.success("Site saved", {
                        autoClose: 3500,
                    });
                } else {
                    toast.error("Failed to save Site", {
                        autoClose: 3500,
                    });
                }
            })
            .catch(function(error) {
                console.log(error);

                toast.error("Failed to save Site", {
                    autoClose: 3500,
                });
            });
    }
}
