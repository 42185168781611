import "./ModalDialog.css";

import React from "react";
import Modal from "react-modal";

const modalStyles = {
    content: {
        position: "absolute",
        top: "10%",
        left: "30%",
        right: "30%",
        bottom: "10%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column"
    }
};

Modal.setAppElement("#root");

export default function ModalDialog(props) {
    return (
        <Modal
            isOpen={true}
            onRequestClose={props.closeModal}
            style={modalStyles}
            contentLabel="Modal selection"
        >
            <h2 align="center" className="modal-title">
                {props.title}
            </h2>

            <div className="modal-content-list">{props.children}</div>

            <div className="button-row">
                <button
                    type="button"
                    className="btn btn-primary add-site-button-dialog"
                    onClick={props.addSelected}
                >
                    {props.addButtonText !== undefined ? props.addButtonText : "Add"}
                </button>
                <button
                    type="button"
                    className="btn btn-default cancel-button-dialog"
                    onClick={props.closeModal}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
}
