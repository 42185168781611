import React, { Component } from "react";
import { adalApiFetch } from "../adalConfig";

export class Users extends Component {
    displayName = Users.name;

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true };

        adalApiFetch(fetch, "api/Users", {})
            .then(response => response.json())
            .then(data => {
                this.setState({ users: data, loading: false });
            });
    }

    static renderUsersTable(users) {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>Email</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.userId}>
                            <td>{user.userId}</td>
                            <td>{user.email}</td>
                            <td>{user.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading ? (
            <p>
                <em>Loading...</em>
            </p>
        ) : (
            Users.renderUsersTable(this.state.users)
        );

        return (
            <div>
                <h1>Users</h1>
                {contents}
            </div>
        );
    }
}
