import React, { Component } from "react";
import { adalApiFetch } from "../../adalConfig";
import { ToastContainer, toast } from "react-toastify";

import "./Invoices.css";
import InvoicesTable from "./InvoicesTable";
import InvoiceFilter from "./InvoiceFilter";
import FormatFilterQuery from "./filterUtils";

import { filter, includes } from "lodash";

export class Invoices extends Component {
    displayName = Invoices.name;

    constructor(props) {
        super(props);
        this.state = {
			invoices: [],
			loading: true,
            count: 0,
            activePage: 1,
            filters: '',
            netvisorDisabled: false,
        };

        this.sendInvoices = this.sendInvoices.bind(this);
        this.revertInvoiceAsNew = this.revertInvoiceAsNew.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        adalApiFetch(fetch, "api/invoices/netvisordisabled", {})
            .then(response => response.text().then(text => {
                this.setState({ netvisorDisabled: text === 'true'});
            }));

        adalApiFetch(fetch, "api/invoices/count/accepted", {})
            .then(response => response.text().then(text => {
                this.setState({ count: Number(text) });
				adalApiFetch(fetch, "api/Invoices/accepted?page=" + this.state.activePage, {})
                    .then(response => response.json())
                    .then(data => {
						this.setState({ invoices: data, loading: false });
					});
            }));
    }

	handlePageChange(activePage) {
        adalApiFetch(fetch, "api/Invoices/accepted?page=" + activePage + this.state.filters, {})
            .then(response => response.json())
            .then(data => {
                this.setState({ activePage: activePage, invoices: data });
			})
			.catch(error => {
				toast.error("Failed to load invoices", {
					autoClose: 3500
				});
			});
    }

	handleChange(filterArray) {
		let filterQuery = FormatFilterQuery(filterArray);

		if (filterQuery !== this.state.filters) {
			adalApiFetch(fetch, "api/invoices/count/accepted" + (filterQuery !== '' ? '?' + filterQuery.slice(1) : ''), {})
				.then(response => response.text().then(text => {
					this.setState({ count: Number(text) });
					adalApiFetch(fetch, "api/Invoices/accepted?page=1" + filterQuery, {})
						.then(response => response.json())
						.then(data => {
							this.setState({ activePage: 1, invoices: data, filters: filterQuery });
						})
						.catch(error => {
							toast.error("Failed to load invoices", {
								autoClose: 3500
							});
						});
				}))
				.catch(error => {
					toast.error("Failed to load invoice count", {
						autoClose: 3500
					});
				});
		}
    }

    sendInvoices(invoiceIds) {
        const invoicesToSend = filter(this.state.invoices, function(invoice) {
            return includes(invoiceIds, invoice.invoiceId);
        });

        adalApiFetch(fetch, "api/Invoices/send", {
            method: "POST",
            body: JSON.stringify(invoicesToSend),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(function(response) {
                if (response.ok) {
                    return null;
                } else {
                    throw new Error(
                        "Error: " + response.status + " while accepting invoice"
                    );
                }
            })
            .then(data => {
                toast.success("Invoices sent successfully", {
                    autoClose: 3500
                });

                this.setState({
                    invoices: []
                });
            })
            .catch(function(error) {
                console.log(error);
                toast.error("Failed to send invoice", {
                    autoClose: 3500
                });
            });
    }

    revertInvoiceAsNew(invoiceIds) {
        adalApiFetch(fetch, "api/Invoices/revert", {
            method: "POST",
            body: JSON.stringify(invoiceIds),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(function (response) {
                if (response.ok) {
                    return null;
                } else {
                    throw new Error(
                        "Error: " + response.status + " while reverting invoice"
                    );
                }
            })
            .then(data => {
                toast.success("Invoices reverted successfully", {
                    autoClose: 3500
                });

                this.setState({
                    invoices: []
                });
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Failed to revert invoice", {
                    autoClose: 3500
                });
            });
    }

    render() {
		if (this.state.loading) {
			return (
				<div className="invoice-list">
					<h1>Invoices (accepted)</h1>
					<p>
						<em>Loading...</em>
					</p>
				</div>
			);
		}

        return (
            <div className="invoice-list">
                <h1>Invoices (accepted)</h1>

                <InvoiceFilter handleChange={this.handleChange} />

                <InvoicesTable
                    totalCount={this.state.count}
                    activePage={this.state.activePage}
                    invoices={this.state.invoices}
                    showAction
                    actionName={this.state.netvisorDisabled ? "Mark as sent" : "Send to Netvisor"}
                    onSelectedIdsAction={this.sendInvoices}
                    showSecondaryAction
                    secondaryActionName="Revert to New"
                    onSelectedIdsSecondaryAction={this.revertInvoiceAsNew}
                    handlePageChange={this.handlePageChange}
                />
                <ToastContainer />
            </div>
        );
    }
}
