import { AuthenticationContext, adalFetch } from 'react-adal';

export const adalConfig = {
	tenant: 'd6416b51-d023-4588-802e-74cab6536b94',
	clientId: process.env.REACT_APP_CLIENT_ID,
	endpoints: {
		api: process.env.REACT_APP_CLIENT_ID,
	},
	cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
	adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
