import "./Customer.css";
import { adalApiFetch } from "../../adalConfig";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { filter, without } from "lodash";

const NewCustomerButton = withRouter(({ history }) => (
    <button
        type="button"
        className="btn btn-primary add-new-customer-button"
        onClick={() => {
            history.push("/customer");
        }}
    >
        Add new
    </button>
));

const CustomerList = props => {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Customer Code</th>
                    <th>Name</th>
                    <th>Contact email</th>
                    <th>Contact</th>
                    <th>Sites</th>
                    <th>Account manager</th>
                </tr>
            </thead>
            <tbody>
                {props.customers.map(customer => (
                    <tr
                        onClick={() => props.editCustomer(customer)}
                        key={customer.customerId}
                        style={{ cursor: "pointer" }}
                    >
                        <td>{customer.customerCode}</td>
                        <td>{customer.name}</td>
                        <td>{customer.contactEmail}</td>
                        <td>{customer.contactName}</td>
                        <td>{customer.siteCount}</td>
                        <td>{customer.accountManagerEmail}</td>
                        <td>
                            <Link to={"/customer/" + customer.customerId}>
                                <i className="fa fa-pencil" />
                            </Link>
                        </td>
                        <td>
                            {props.status === "active" && customer.siteCount === 0 && <button
                                id="remove-customer-button"
                                type="button"
                                className="btn btn-link"
                                onClick={() => props.removeCustomer(customer)}
                            >
                                <i className="fa fa-trash" />
                            </button>}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

class Customers extends Component {
    displayName = Customers.name;

    constructor(props) {
        super(props);
        this.state = { customers: [], filteredCustomers: [], loading: true };

        this.removeCustomer = this.removeCustomer.bind(this);
        this.fetchCustomers = this.fetchCustomers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
    }

    componentDidMount() {
        this.fetchCustomers();
    }

    fetchCustomers() {
        adalApiFetch(fetch, "api/Customers/?status=" + this.props.status, {})
            .then(response => response.json())
            .then(data => {
                this.setState({
                    customers: data,
                    filteredCustomers: data,
                    loading: false,
                    filterName: "",
                    filterEmail: "",
                    filterContact: "",
                    filterManager: ""
                });
            });
    }

    handleChange(evt) {
        if (evt.target.name === "reset") {
            this.setState({
                filterName: "",
                filterEmail: "",
                filterContact: "",
                filterManager: ""
            });
        } else {
            this.setState({
                [evt.target.name]: evt.target.value.toLowerCase()
            });
        }

        this.setState(prevState => {
            const filteredCustomers = filter(this.state.customers, function(
                customer
            ) {
                const name = customer.name.toLowerCase();
                const email = customer.contactEmail.toLowerCase();
                const contact = customer.contactName.toLowerCase();
                const manager =
                    customer.accountManagerEmail !== null
                        ? customer.accountManagerEmail.toLowerCase()
                        : "";

                return (
                    name.includes(prevState.filterName) &&
                    email.includes(prevState.filterEmail) &&
                    contact.includes(prevState.filterContact) &&
                    manager.includes(prevState.filterManager)
                );
            });

            return { filteredCustomers };
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="customers-list">
                    <h1>Customers</h1>
                    <p>
                        <em>Loading...</em>
                    </p>
                </div>
            );
        }

        return (
            <div>
                <div className="customers-list">
                    {this.props.status === "active" ? <h1>Customers</h1> : <h1>Removed customers</h1>}
                    <div>
                        <form>
                            <fieldset className="form-group">
                                <input
                                    name="filterName"
                                    id="filterName"
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    style={{
                                        width: "220px",
                                        display: "inline"
                                    }}
                                    onChange={this.handleChange}
                                />
                                <input
                                    name="filterEmail"
                                    id="filterEmail"
                                    type="text"
                                    placeholder="Email"
                                    className="form-control"
                                    style={{
                                        width: "220px",
                                        display: "inline",
                                        marginLeft: "10px"
                                    }}
                                    onChange={this.handleChange}
                                />
                                <input
                                    name="filterContact"
                                    id="filterContact"
                                    type="text"
                                    placeholder="Contact"
                                    className="form-control"
                                    style={{
                                        width: "220px",
                                        display: "inline",
                                        marginLeft: "10px"
                                    }}
                                    onChange={this.handleChange}
                                />
                                <input
                                    name="filterManager"
                                    id="filterManager"
                                    type="text"
                                    placeholder="Account manager"
                                    className="form-control"
                                    style={{
                                        width: "220px",
                                        display: "inline",
                                        marginLeft: "10px"
                                    }}
                                    onChange={this.handleChange}
                                />
                                <input
                                    name="reset"
                                    className="btn btn-default"
                                    type="reset"
                                    value="Clear"
                                    style={{
                                        marginLeft: "10px"
                                    }}
                                    onClick={this.handleChange}
                                />
                            </fieldset>
                        </form>
                    </div>

                    <CustomerList
                        customers={this.state.filteredCustomers}
                        removeCustomer={this.removeCustomer}
                        editCustomer={this.editCustomer}
                        status={this.props.status}
                    />

                    <ToastContainer />
                </div>
                <div id="customer-footer" className="customer-footer">
                    <NewCustomerButton />
                </div>
            </div>
        );
    }

    removeCustomer(customer) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-dialog">
                        <h1>Are you sure?</h1>
                        <p>You want to remove customer {customer.name}</p>
                        <div className="confirm-buttons">
                            <button
                                className="btn btn-primary"
                                onClick={onClose}
                            >
                                No
                            </button>
                            <button
                                className="btn btn-danger confirm-buttons-yes"
                                onClick={() => {
                                    this.handleDeleteCustomer(customer);
                                    onClose();
                                }}
                            >
                                Yes, Remove!
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    editCustomer(customer) {
        this.props.history.push("/customer/" + customer.customerId);
    }

    handleDeleteCustomer(customer) {
        const url = "/api/Customers/" + customer.customerId;

        adalApiFetch(fetch, url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(function(response) {
                if (response.ok) {
                    return response;
				} else {
                    throw new Error(
                        "Failed to remove " +
                            customer.name +
                            ". Error: " +
                            response.status
                    );
                }
            })
            .then(response => {
                console.log(response);

                toast.success("Customer " + customer.name + " removed", {
                    autoClose: 3500
                });

                const updatedCustomers = without(
                    this.state.customers,
                    customer
                );
                this.setState({ customers: updatedCustomers });
            })

            .catch(function(error) {
                console.log(error);

                toast.error(error.message, {
                    autoClose: 5000
                });
            });
    }
}

export default withRouter(Customers);
