import React, { Component } from "react";
import { adalApiFetch } from "../../adalConfig";
import { ToastContainer, toast } from "react-toastify";

import "./Invoices.css";
import InvoicesTable from "./InvoicesTable";
import InvoiceFilter from "./InvoiceFilter";
import FormatFilterQuery from "./filterUtils";

export class InvoicesHistory extends Component {
    displayName = InvoicesHistory.name;

    constructor(props) {
        super(props);
        this.state = {
            invoices: [],
            loading: true,
            count: 0,
            activePage: 1,
            filters: '',
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        adalApiFetch(fetch, "api/invoices/count/" + this.props.status, {})
            .then(response => response.text().then(text => {
                this.setState({ count: Number(text) });
                adalApiFetch(fetch, "api/Invoices/" + this.props.status + "?page=" + this.state.activePage, {})
                    .then(response => response.json())
                    .then(data => {
                        this.setState({ invoices: data, loading: false });
                    });
            }));
    }

    handlePageChange(activePage) {
        adalApiFetch(fetch, "api/Invoices/" + this.props.status + "?page=" + activePage + this.state.filters, {})
            .then(response => response.json())
            .then(data => {
                this.setState({ activePage: activePage, invoices: data });
			})
			.catch(error => {
				toast.error("Failed to load invoices", {
					autoClose: 3500
				});
			});
    }

	handleChange(filterArray) {
		let filterQuery = FormatFilterQuery(filterArray);

		if (filterQuery !== this.state.filters) {
			adalApiFetch(fetch, "api/invoices/count/" + this.props.status + (filterQuery !== '' ? '?' + filterQuery.slice(1) : ''), {})
				.then(response => response.text().then(text => {
					this.setState({ count: Number(text) });
					adalApiFetch(fetch, "api/Invoices/" + this.props.status + "?page=1" + filterQuery, {})
						.then(response => response.json())
						.then(data => {
							this.setState({ activePage: 1, invoices: data, filters: filterQuery });
						})
						.catch(error => {
							toast.error("Failed to load invoices", {
								autoClose: 3500
							});
						});
				}))
				.catch(error => {
					toast.error("Failed to load invoice count", {
						autoClose: 3500
					});
				});
		}
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="invoice-list">
                    <h1>Invoices ({this.props.status})</h1>
                    <p>
                        <em>Loading...</em>
                    </p>
                </div>
            );
        }

        return (
            <div className="invoice-list">
                <h1>Invoices ({this.props.status})</h1>

                <InvoiceFilter handleChange={this.handleChange} />

                <InvoicesTable
                    totalCount={this.state.count}
                    activePage={this.state.activePage}
                    invoices={this.state.invoices}
                    showAction={false}
                    actionName={null}
                    onSelectedIdsAction={null}
                    showSecondaryAction={false}
                    handlePageChange={this.handlePageChange}
                />
                <ToastContainer />
            </div>
        );
    }
}
