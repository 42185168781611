import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { NavMenu } from "./NavMenu";

const divStyle = {
    height: "50px"
};

export class Layout extends Component {
    displayName = Layout.name;

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col>
                        <NavMenu />
                    </Col>
                </Row>
                <Row>
                    <div style={divStyle} />
                    <Col>{this.props.children}</Col>
                </Row>
            </Grid>
        );
    }
}
