import React, { Component } from "react";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import { dateFormat } from "../../utils/constants";
import { adalApiFetch } from "../../adalConfig";
import Link from "react-router-dom/Link";
import { parse, format } from "date-fns";

export class Invoice extends Component {
    displayName = Invoice.name;

    constructor(props) {
        super(props);
        this.state = {
            invoice: null,
            loading: true,
            enableRemoveInvoice: false,
            removeText: "",
        };

        this.disableRemoveInvoice = this.disableRemoveInvoice.bind(this);
        this.removeInvoice = this.removeInvoice.bind(this);
        this.removeTextUpdated = this.removeTextUpdated.bind(this);
        this.revertInvoice = this.revertInvoice.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.invoiceId) {
            adalApiFetch(
                fetch,
                "/api/Invoices/" + this.props.match.params.invoiceId,
                {}
            )
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        invoice: data,
                        loading: false,
                    });
                });
        }
    }

    renderInvoiceContent(invoice) {
        let rows = [];

        for (let site of invoice.status !== 4 ? invoice.sites : []) {
            const licenseKey = site.name;

            const siteProductRows =
                site.product.length > 5 ? site.product.length : 5;

            for (let i = 0; i < siteProductRows; i++) {
                const key = licenseKey + "p" + i;

                let product = null;
                if (site.product.length >= i + 1) {
                    product = site.product[i];
                }

                rows.push(
                    <tr key={key}>
                        <td className="invoice-site-column">
                            {this.siteInfoRow(site, i)}
                        </td>

                        <td>{this.productInfoColumn(product, 1)}</td>
                        <td>{this.productInfoColumn(product, 2)}</td>
                        <td>{this.productInfoColumn(product, 3)}</td>
                        <td>{this.productInfoColumn(product, 4)}</td>
                        <td>
                            <span className="product-row-amount">
                                {this.productInfoColumn(product, 5)}
                            </span>
                        </td>
                    </tr>
                );
            }
        }

        return rows;
    }

    productInfoColumn(product, columnIndex) {
        if (!product) {
            return "";
        }

        switch (columnIndex) {
            case 1:
                return product.name;
            case 2:
                return this.state.invoice.invoiceInterval;
            case 3:
                return product.count;
            case 4:
                return Number(product.price).toFixed(3);
            case 5:
                return Number(product.total).toFixed(2);
            default:
                return "";
        }
    }

    downloadInvoicePDF() {
        let invoiceId = this.state.invoice.invoiceId;
        let invoiceNumber = this.state.invoice.invoiceNumber;

        this.setState({ loading: true }, () => {
            adalApiFetch(fetch, "/api/Invoices/" + invoiceId + "/pdf", {
                headers: {
                    "Accept": "application/pdf",
                },
            })
                .then(function (response) {
                    if (response.ok) {
                        return response.arrayBuffer();
                    } else {
                        throw new Error(
                            "Error: " + response.status + " while downloading invoice PDF"
                        );
                    }
                })
                .then(data => {
                    this.setState({ loading: false });
                    toast.success("Invoice PDF loaded successfully", {
                        autoClose: 3500,
                    });

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
                    link.download = 'invoice_' + invoiceId + '_' + invoiceNumber + '.pdf';
                    document.body.appendChild(link);
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                    link.remove();
                    window.URL.revokeObjectURL(link.href);
                })
                .catch(function (error) {
                    console.log(error);

                    toast.error(error.message, {
                        autoClose: 3500,
                    });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    }

    siteInfoRow(site, rowIndex) {

        // End date is add invoiceinterval months then substract 1 day
        // if start day is last of month, then end must be last of month - 1 day
        let startDate = moment(this.state.invoice.invoicePeriodStartDate ?
            this.state.invoice.invoicePeriodStartDate :
            this.state.invoice.invoiceDate);
        let endDate = moment(startDate).add(this.state.invoice.invoiceInterval, "months");

        if (startDate.date() === startDate.daysInMonth()) {
            endDate = endDate.date(endDate.daysInMonth());
        }
        endDate.subtract(1, "days");

        let siteColumn = site.status === 1 ? <Link to={"/site/" + site.siteId}>{site.name}</Link> : <div style={{ color: 'red' }}>{ site.name } (removed or not invoice customer)</div>;
        switch (rowIndex) {
            case 0:
                return (
                    <div className="row">
                        <div className="col-md-6 invoice-site-info-title">
                            Name:
                        </div>

                        <div className="col-md-6">
                            {siteColumn}
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className="row">
                        <div className="col-md-6 invoice-site-info-title">
                            Subscription start date
                        </div>

                        <div className="col-md-6">
                            {moment(site.subscriptionStartDate).format(
                                dateFormat
                            )}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="row">
                        <div className="col-md-6 invoice-site-info-title">
                            Subscription end date
                        </div>

                        <div className="col-md-6">
                            {site.subscriptionEndDate && moment(site.subscriptionEndDate).format(
                                dateFormat
                            )}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="row">
                        <div className="col-md-6 invoice-site-info-title">
                            License period
                        </div>
                        <div className="col-md-6">
                            {moment(startDate).format(
                                dateFormat
                            )}
                            {" - "}
                            {endDate.format(dateFormat)}
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="row">
                        <div className="col-md-6 invoice-site-info-title">
                            Device count
                        </div>

                        <div className="col-md-6">{site.deviceCount}</div>
                    </div>
                );
            default:
                return "";
        }
    }

    invoiceStatusString(status) {
        switch (status) {
            case 1:
                return "New";
            case 2:
                return "Accepted";
            case 3:
                return "Sent";
            case 4:
                return "Removed";
            default:
                return "not defined";
        }
    }

    removeInvoiceView() {
        return (
            <div className="invoice-remove-container">
                <textarea
                    name="removeCommentText"
                    cols="60"
                    rows="5"
                    onChange={this.removeTextUpdated}
                    placeholder="Comment (mandatory)"
                />

                <div className="button-row">
                    <button
                        type="button"
                        className="btn btn-default"
                        onClick={this.disableRemoveInvoice}>
                        Cancel
                    </button>
                    <button
                        disabled={!this.state.removeText}
                        type="button"
                        className="btn btn-primary invoice-confirm-remove-button"
                        onClick={this.removeInvoice}>
                        Confirm remove
                    </button>
                </div>
            </div>
        );
    }

    removeTextUpdated(event) {
        this.setState({ removeText: event.target.value });
    }

    removeInvoice() {
        const url = "api/Invoices/" + this.state.invoice.invoiceId + "/remove";
        adalApiFetch(fetch, url, {
            method: "PUT",
            body: JSON.stringify(this.state.removeText),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function(response) {
                if (response.ok) {
                    return null;
                } else {
                    throw new Error(
                        "Error: " + response.status + " while removing invoice"
                    );
                }
            })
            .then(data => {
                let invoice = { ...this.state.invoice };
                invoice.status = 4;
                this.setState({ invoice: invoice });

                toast.success("Invoice removed successfully", {
                    autoClose: 3500,
                });
            })
            .catch(function(error) {
                console.log(error);

                toast.error(error.message, {
                    autoClose: 3500,
                });
            });
    }

    acceptInvoice() {
        let invoice = this.state.invoice;

        adalApiFetch(fetch, "api/Invoices/accept", {
            method: "POST",
            body: JSON.stringify([invoice.invoiceId]),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function(response) {
                if (response.ok) {
                    return null;
                } else {
                    throw new Error(
                        "Error: " + response.status + " while accepting invoice"
                    );
                }
            })
            .then(data => {
                toast.success("Invoice accepted successfully", {
                    autoClose: 3500,
                });

                invoice.status = 2;
                this.setState({ invoice: invoice });
            })
            .catch(function(error) {
                console.log(error);
                toast.error("Failed to accept invoice", {
                    autoClose: 3500,
                });
            });
    }

    revertInvoice() {
        let invoice = this.state.invoice;

        adalApiFetch(fetch, "api/Invoices/revert", {
            method: "POST",
            body: JSON.stringify([invoice.invoiceId]),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                if (response.ok) {
                    return null;
                } else {
                    throw new Error(
                        "Error: " + response.status + " while reverting invoice"
                    );
                }
            })
            .then(data => {
                toast.success("Invoice reverted successfully", {
                    autoClose: 3500,
                });

                invoice.status = 1;
                this.setState({ invoice: invoice });
            })
            .catch(function (error) {
                console.log(error);
                toast.error("Failed to accept invoice", {
                    autoClose: 3500,
                });
            });
    }


    enableRemoveInvoice() {
        this.setState({ enableRemoveInvoice: true });
    }

    disableRemoveInvoice() {
        this.setState({ enableRemoveInvoice: false });
    }

    render() {
        if (this.state.loading) {
            return (
                <div id="invoice-view">
                    <div className="path-view" />
                    <div className="invoice-customer-info">
                        <div className="invoice-customer-title-row">
                            <h2>Invoice</h2>
                        </div>
                        <p>
                            <em>Loading...</em>
                        </p>
                    </div>
                </div>
            );
        }

        const invoice = this.state.invoice;

        return (
            <div id="invoice-view">
                <div className="path-view" />

                <div className="invoice-customer-info">
                    <div className="invoice-customer-title-row">
                        <h2>
                            {"Invoice " + invoice.invoiceNumber &&
                                invoice.invoiceNumber}
                        </h2>
                    </div>

                    <div className="row">
                        <div className="col-md-6 invoice-customer-column">
                            <div className="invoice-customer-title">
                                Customer
                            </div>
                            <div className="invoice-customer-name">
                                <Link
                                    to={
                                        "/customer/" +
                                        invoice.customer.customerId
                                    }>
                                    {invoice.customer.name}
                                </Link>
                            </div>
                            <div>{invoice.customer.contactEmail}</div>
                        </div>

                        <div className="col-md-1 invoice-customer-column">
                            <div className="invoice-customer-title">
                                Site group
                            </div>
                            <div className="invoice-customer-title">
                                Interval
                            </div>
                            <div className="invoice-customer-title">
                                Next invoice date
                            </div>
                        </div>

                        <div className="col-md-2 invoice-customer-column">
                            <div className="customer-info-text">
                                {invoice.siteGroupId}
                            </div>
                            <div className="customer-info-text">
                                {invoice.invoiceInterval} months
                            </div>
                            <div className="customer-info-text">
                                {format(
                                    parse(invoice.nextInvoiceDate),
                                    dateFormat
                                )}
                            </div>
                        </div>
                        <div className="col-md-1 invoice-customer-column">
                            <div className="invoice-customer-title">Date</div>
                            <div className="invoice-customer-title">Start Date</div>
                            <div className="invoice-customer-title">Amount</div>
                            <div className="invoice-customer-title">
                                Currency
                            </div>
                            <div className="invoice-customer-title">Status</div>
                            <div className="invoice-customer-title">Account Manager</div>
                        </div>
                        <div className="col-md-2 invoice-customer-column">
                            <div className="customer-info-text">
                                {moment(invoice.invoiceDate).format(dateFormat)}
                            </div>
                            <div className="customer-info-text">
                                {invoice.invoicePeriodStartDate ? moment(invoice.invoicePeriodStartDate).format(dateFormat) : '-'}
                            </div>
                            <div className="customer-info-text">
                                {
                                    <span className="product-row-amount">
                                        {Number(invoice.amount).toFixed(2)}{" "}
                                        {invoice.currency}
                                    </span>
                                }
                            </div>
                            <div className="customer-info-text">
                                {invoice.currency}
                            </div>
                            <div className="customer-info-text">
                                {this.invoiceStatusString(invoice.status)}
                            </div>
                            <div className="customer-info-text">
                                {invoice.customer.accountManager}
                            </div>
                        </div>
                    </div>

                    <div>
                        {invoice.status === 4 ? (
                            <div className="invoice-customer-title">
                                <b>Message: </b>
                                {invoice.message}
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="invoice-site-info">
                    <table
                        id="invoice-site-table"
                        className="table table-striped"
                        cellSpacing="0"
                        cellPadding="0">
                        <thead>
                            <tr>
                                <th>Site details</th>
                                <th>Name</th>
                                <th>Months</th>
                                <th>Count</th>
                                <th>Price</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderInvoiceContent(invoice)}</tbody>
                    </table>
                </div>

                <div className="invoice-footer">
                    <button
                        disabled={invoice.status === 3 || invoice.status === 4}
                        type="button"
                        className="btn btn-primary invoice-button"
                        onClick={() => this.enableRemoveInvoice()}>
                        Remove
                    </button>
                    <button
                        disabled={invoice.status !== 1}
                        type="button"
                        className="btn btn-success accept-one-invoice-button invoice-button"
                        onClick={() => this.acceptInvoice()}>
                        Accept
                    </button>
                    <button
                        disabled={invoice.status !== 2}
                        type="button"
                        className="btn btn-warning invoice-button"
                        onClick={() => this.revertInvoice()}>
                        Revert to New
                    </button>
                    <button
                        disabled={invoice.status !== 3}
                        type="button"
                        className="btn btn-primary invoice-button"
                        onClick={() => this.downloadInvoicePDF()}>
                        Get PDF
                    </button>
                </div>

                {this.state.enableRemoveInvoice
                    ? this.removeInvoiceView()
                    : null}

                <ToastContainer />
            </div>
        );
    }
}
